<template>
  <div>
    <div
      v-if="user.role === 'manager'"
      class="d-flex align-items-center mt-3"
    >
      <label class="mr-1 h3">Bon de transfert</label>

    </div>
    <!-- input Recherche -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Recherche</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          :to="{ name: 'bon-transfert-add' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter bon transfert</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="transferVouchers"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          v-if="props.column.field === 'id'"
          class="text-nowrap"
        >
          <qrcode-vue
            style="display:inline"
            :value="props.row.id.toString()"
            :size="size"
            level="H"
          />
          <span class="ml-2">{{ props.row.id }}</span>

        </div>
        <!-- Column: Name -->
        <div
          v-else-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-success"
              :to="{ name: 'bon-transfert-show',
                     params: { id: props.row.id} ,}"
            >
              <feather-icon
                :id="`invoice-row-${props.row.id}-show-icon`"
                icon="EyeIcon"
                class="cursor-pointer mr-1"
                size="16"
              />
            </router-link>
            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
          </span>
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BFormGroup, BFormInput, BButton,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BTooltip,
    QrcodeVue,
  },
  data() {
    return {
      size: 80,
      currentPage: 1,
      user: {},
      isLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'code',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'code',
          },
        },
        {
          label: 'source',
          field: 'from_repository',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher source',
          },
        },
        {
          label: 'destination',
          field: 'to_repository',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher destination',
          },
        },
        {
          label: 'livreur',
          field: 'delivery_man',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher livreur',
          },
        },
        {
          label: 'N° des colis',
          field: 'orders_count',
          filterOptions: {
            enabled: true,
            placeholder: 'N° des colis',
          },
        },
        {
          label: 'date | heure',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      transferVouchers: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.user = storeAuth.state.user
    this.getTransferVouchers()
  },
  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    async getTransferVouchers() {
      this.isLoading = true
      const { data } = await axios.get('api/transfer-vouchers/demande/')
      this.transferVouchers = data
      this.isLoading = false
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
